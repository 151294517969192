<template>
  <div class="w-full h-full pt-5 px-3">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template>
        <div class="flex flex-row justify-start items-center gap-4">
          <h1 class="text-xl text-left font-extrabold">Employees</h1>
          <breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template>
        <div class="flex flex-col gap-2">
          <template>
            <Card class="mt-6 p-5 flex flex-col gap-5">
              <template v-if="mode === 'admin'">
                <div class="flex mb-6 mr-3" style="justify-content: flex-end">
                  <s-button
                    label="New Redeployment"
                    class="text-white button-class"
                    :style="{background: appearance.buttonColor || defaultBTNColor}"
                    @onClick="priviledgedActions(null, 'create')"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <CardFooter
                  reloadcard
                  @reload="onGetQuery"
                  @sortType="onGetQuery({ sort: $event })"
                  @actionModal="onFilter"
                  @searchResult="onGetQuery({ search: $event })"
                />
              </template>
            </Card>
          </template>
          <template v-if="hasModuleAccess">
            <sTable
              v-if="employees.length || isFetching"
              :headers="headers"
              :items="employees"
              :loading="isFetching"
              :pagination-list="pagination"
              @page="onGetQuery({ page: $event })"
              @itemsPerPage="onGetQuery({ perPage: $event })"
              page-sync
            >
              <template v-slot:item="{ item }">
                <template v-if="item.userId">
                  <div class="flex flex-row items-center whitespace-nowrap">
                    <template>
                      <img
                        v-if="item.data.photo"
                        :src="item.data.photo"
                        alt="profile pic"
                        class="w-8 h-8 rounded"
                      />
                      <div
                        v-else
                        class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2"
                      >
                        {{
                          $getInitials(`${item.data.fname} ${item.data.lname}`)
                        }}
                      </div>
                    </template>
                    <div class="ml-2 text-sm flex flex-col justify-Start">
                      <span
                        class="font-bold cursor-pointer"
                        style="color: #525252"
                      >
                        {{ item.data.fname }} {{ item.data.lname }}
                      </span>
                      <div
                        class="text-romanSilver text-xs capitalize font-semibold"
                      >
                        <span v-if="item.data.userDesignation">
                          {{ item.data.userDesignation.name }}
                        </span>
                        <span v-else>-</span>
                      </div>
                    </div>
                  </div>
                </template>
                <span v-if="item.createdAt" class="text-sm text-jet">
                  {{
                    $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy")
                  }}
                </span>
                <div v-if="item.newDesignation" class="text-sm text-jet">
                  <span v-if="item.data.newDesignation">
                    {{ item.data.newDesignation.name }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div v-if="item.newLocation" class="text-sm text-jet">
                  <span v-if="item.data.newLocation">
                    {{ item.data.newLocation.name }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div v-if="item.effectiveDate" class="text-sm text-jet">
                  <span v-if="item.data.effectiveDate">
                    {{
                      $DATEFORMAT(
                        new Date(item.data.effectiveDate),
                        "MMMM dd, yyyy"
                      )
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
                <p
                  v-else-if="item.status"
                  class="text-center capitalize w-24"
                  :class="{
                    approved: item.data.status === 'approved',
                    disapproved: item.data.status === 'disapproved',
                    cancelled: item.data.status === 'cancelled',
                    pending: item.data.status === 'pending',
                    disapproved: item.data.status === 'terminated',
                    progress: item.data.status === 'in_progress',
                  }"
                >
                  {{ item.data.status }}
                </p>
                <span v-if="item.id">
                  <Menu left top="-150" margin="24" class="my-2 p-0">
                    <template v-slot:title>
                      <icon icon-name="more_icon" size="xs" />
                    </template>
                    <div
                      class="w-44 flex flex-col justify-start items-center gap-3 px-3 py-2"
                    >
                      <template
                        v-if="!item.data.approvalInitiated && mode === 'admin'"
                      >
                        <div
                          class="w-full"
                          @click="priviledgedActions(item.data, 'update')"
                        >
                          <div
                            class="w-full flex justify-start items-center gap-2 cursor-pointer"
                          >
                            <Icon
                              icon-name="edit"
                              class-name="text-blueCrayola"
                              size="xs"
                            />
                            <p class="font-normal text-sm text-darkPurple">
                              Edit
                            </p>
                          </div>
                        </div>
                      </template>
                      <div
                        class="w-full"
                        @click="priviledgedActions(item.data, 'view_details')"
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-eye"
                            class-name="text-darkPurple"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            View Details
                          </p>
                        </div>
                      </div>
                      <div
                        class="w-full"
                        @click="priviledgedActions(item.data.id, 'approval')"
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-upgrade"
                            class-name="text-mediumSeaGreen"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            Approval Status
                          </p>
                        </div>
                      </div>
                      <template
                        v-if="!item.data.approvalInitiated && mode === 'ess'"
                      >
                        <div
                          class="w-full ml-1"
                          @click="priviledgedActions(item.data.id, 'cancel')"
                        >
                          <div
                            class="w-full flex justify-start items-center gap-2 cursor-pointer"
                          >
                            <Icon
                              icon-name="icon-unavailable"
                              class-name="text-romanSilver"
                              size="xms"
                            />
                            <p class="font-normal text-sm text-darkPurple">
                              Cancel
                            </p>
                          </div>
                        </div>
                      </template>
                    </div>
                  </Menu>
                </span>
              </template>
            </sTable>
            <div
              v-else
              class="w-full flex flex-col justify-center items-center"
            >
              <icon icon-name="empty_agreement" size="l" style="width: 300px" />
              <div class="w-1/2 text-base text-center" v-if="mode === 'admin'">
                There are no pending redeployments. To view and manage employee
                redeployments, kindly click below to start a new employee
                redeployment.
              </div>
              <div class="w-1/2 text-base text-center" v-else>
                There are no pending redeployments.
              </div>
              <div
                @click="priviledgedActions(null, 'create')"
                class="flex justify-start items-center cursor-pointer gap-2 mt-4"
                v-if="mode === 'admin'"
              >
                <Icon icon-name="icon-plus" size="xs" />
                <span class="font-semibold text-base text-flame leading-5">
                  New Redeployment
                </span>
              </div>
            </div>
          </template>
          <div v-else>
            <ErrorComponent />
          </div>
        </div>
      </template>
      <template>
        <Show
          ref="show_redeployment"
          :mode="mode"
          @trail="$refs.approval_status.toggle($event)"
        />
        <CreateRedeployment
          ref="create_redeployment"
          @redeployed="onGetQuery"
        />
        <Update
          @redeployed="onGetQuery"
          ref="update_redeploy"
          @close="$refs.update_redeploy.toggle()"
        />
        <ApprovalProgress ref="approval_status" />
      </template>
    </template>

    <FilterTable
      v-if="isOpenFilter"
      :filter-data="filterOptions"
      @close="isOpenFilter = false"
      @getParams="[onGetQuery($event), isOpenFilter = false]"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as _ from "lodash";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import SButton from "@scelloo/cloudenly-ui/src/components/button";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  components: {
    Breadcrumb,
    STable,
    Card,
    SButton,
    CardFooter,
    FilterTable,
    Menu,
    ErrorComponent,
    ApprovalProgress: () => import("@/components/ApprovalStatus"),
    CreateRedeployment: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeActions/Redeployment/create"
      ),
    Update: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeActions/Redeployment/update"
      ),
    Show: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeActions/Redeployment/show"
      ),
  },
  data() {
    return {
      isLoading: true,
      isFetching: true,
      hasModuleAccess: false,
      isOpenFilter: false,
      filterOptions: [],
      employees: [],
      pagination: {},
      queryParams: {},
      designationsOptions: [],
      headers: [
        { title: "Employee Name", value: "userId" },
        { title: "date created", value: "createdAt" },
        { title: "New Designation", value: "newDesignation" },
        { title: "new location", value: "newLocation" },
        { title: "effective date", value: "effectiveDate" },
        { title: "status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      breadcrumbs: [
        { disabled: false, text: "Employee", href: "Employee", id: "Employee" },
        {
          disabled: false,
          text: "Redeployment",
          href: "Redeployment",
          id: "Redeployment",
        },
      ],
      statusOptions: [
        {
          id: "approved",
          name: "Approved",
        },
        {
          id: "pending",
          name: "Pending",
        },
        {
          id: "cancelled",
          name: "Cancelled",
        },
      ],
    };
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    onFilter() {
      this.filterOptions = [
        {
          header: "Designation(s)",
          value: "designationId",
          optionsBox: this.designationsOptions,
        },
        { header: "Status", value: "status", optionsBox: this.statusOptions },
      ];
      this.isOpenFilter = true;
    },
    async onGetQuery(queryParams) {
      try {
        if (this.mode === "admin") {
          await this.$handlePrivilege("redeploymentHR", "viewRegister");
        }
        if (queryParams !== undefined) {
          if (typeof queryParams === 'object') {
            this.queryParams = { ...queryParams };
          } else {
            this.queryParams = queryParams;
          }
          this.getOrgRedeployedEmployees(this.queryParams);
        } else {
          this.queryParams = {};
          this.getOrgRedeployedEmployees("");
        }
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    },
    async priviledgedActions(payload, setAction) {
      try {
        if (setAction === "create") {
          if (this.mode === "admin") {
            await this.$handlePrivilege(
              "redeploymentHR",
              "createNewRedeployment"
            );
          }
          this.$refs.create_redeployment.toggle(payload);
        } else if (setAction === "update") {
          if (this.mode === "admin") {
            await this.$handlePrivilege("redeploymentHR", "editRedeployment");
          }
          this.$refs.update_redeploy.toggle(payload);
        } else if (setAction === "view_details") {
          if (this.mode === "admin") {
            await this.$handlePrivilege(
              "redeploymentHR",
              "viewRedeploymentDetails"
            );
          }
          this.$refs.show_redeployment.toggle(payload);
        } else if (setAction === "approval") {
          if (this.mode === "admin") {
            await this.$handlePrivilege("redeploymentHR", "viewApprovalStatus");
          }
          this.$refs.approval_status.toggle(payload);
        } else if (setAction === "cancel") {
          if (this.mode === "admin") {
            await this.$handlePrivilege("redeploymentHR", "cancelRedeployment");
          }
          this.cancelRedployment(payload);
        }
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    },

    cancelRedployment(id) {
      this.$_cancelRedployment(id)
        .then((response) => {
          this.$toasted.success(response.data.data.message, { duration: 3000 });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },

    async getOrgRedeployedEmployees(params) {
      let queryString = '';
      if (typeof params === 'object') {
        queryString = Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join("&");
      }
      else {
        queryString = params
      }
      if (this.mode === "admin") {
        this.isFetching = true;
        await this.$_getOrgEmployeeRedeployments(queryString).then(
          ({ data }) => {
            this.employees = data.data.employees
              ?.filter((item) => _.size(_.keys(item)))
              .map((item) => ({
                ...item,
                ...item.newDetails.employee,
                payInfo: item.payInfo,
                id: item.newDetails.employee.id,
                userId: item.newDetails.employee.userId,
                createdAt: item.newDetails.employee.createdAt,
                newDesignation: item.newDetails.newDesignation,
                newLocation: item.newDetails.newLocation,
                effectiveDate: item.newDetails.employee.effectiveDate,
                status: item.newDetails.employee.status,
                grossPay: this.setGrossPay(item),
                newPayInfo: item.newDetails.employee.payInfo,
                initiatedBy: item.newDetails.initiatedBy,
              }));
            this.pagination = data.data.meta;
            this.isFetching = false;
          }
        );
      } else {
        this.isFetching = true;
        await this.$_getManagersRedeployment(queryString).then(({ data }) => {
          this.employees = data.data.employees
            ?.filter((item) => _.size(_.keys(item)))
            .map((item) => ({
              ...item,
              ...item.newDetails.employee,
              payInfo: item.payInfo,
              id: item.newDetails.employee.id,
              userId: item.newDetails.employee.userId,
              createdAt: item.newDetails.employee.createdAt,
              newDesignation: item.newDetails.newDesignation,
              newLocation: item.newDetails.newLocation,
              effectiveDate: item.newDetails.employee.effectiveDate,
              status: item.newDetails.employee.status,
              grossPay: this.setGrossPay(item),
              newPayInfo: item.newDetails.employee.payInfo,
              initiatedBy: item.newDetails.initiatedBy,
            }));
          this.pagination = data.data.meta;
          this.isFetching = false;
        });
      }
    },

    setGrossPay(item) {
      if (item.payInfo) {
        return item.payInfo.grossPay ? item.payInfo.grossPay : null;
      }
      return null;
    },

    getAllDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.map((designation) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Designation(s)") {
              filter.optionsBox.push({
                id: designation.id,
                name: designation.name,
              });
            }
          });
          return {};
        });
      });
    },
  },
  async created() {
    this.isLoading = true;

    try {
      if (this.mode === "admin") {
        await this.$handlePrivilege("redeploymentHR", "viewRegister");
      }
      this.hasModuleAccess = true;
      this.getOrgRedeployedEmployees("");
    } catch (error) {
      this.hasModuleAccess = false;
    }

    this.$_getDesignations("").then((response) => {
      response.data.designations.forEach((designation) => {
        this.designationsOptions.push({
          id: designation.id,
          name: designation.name,
        });
      });
    });

    this.isLoading = false;
  },
};
</script>

<style scoped>
.pending {
  background: rgba(233, 147, 35, 0.08);
  color: #e99323;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.progress {
  background: rgba(233, 147, 35, 0.08);
  color: #e99323;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.approved {
  background: rgba(19, 181, 106, 0.08);
  color: #13b56a;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.disapproved {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.cancelled {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.button-class {
  padding: 10px 30px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
</style>
